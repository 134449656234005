.Card,
.CardExpanded {
  position: relative;

  font-size: 14px;
}

.CardExpanded > .Head {
  font-weight: 500;

  color: var(--color-text);
}

.Head {
  display: flex;
  height: 40px;
  padding: 0px 16px;

  color: var(--color-text50);
}

.CurrentSort {
  flex: 1;

  margin: auto 0;
}

.ExpandButton {
  flex: 1;

  text-align: right;
  margin: auto 0;
}

.Card .ExpandButton > * {
  transform: rotate(0deg);
  transition: 0.2s;
}

.CardExpanded .ExpandButton > * {
  transform: rotate(180deg);
  transition: 0.2s;
}

.Body {
  height: 0px;
  transform-origin: 0% 0%;
  transform: scaleY(0);
}

.CardExpanded .Body {
  height: auto;
  transform: scaleY(1);
}

.SortItem {
  padding: 16px 16px;
  color: var(--color-text);
}

.SortItem:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.SortItemDivider {
  width: 100%;
  height: 1px;

  opacity: 0.3;
  background-color: var(--color-text50);
}
