.ContentBody {
  position: fixed;
  top: 51px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  overflow-y: auto;

  background: var(--color-background);
}
