.Card {
  padding: 16px;
  margin-bottom: 2px;

  color: var(--color-text);
  background-color: var(--color-panel);
}

.Quote {
  width: 100%;
  min-width: 100%;
  max-width: 100%;

  min-height: 32px;
  max-height: 128px;

  resize: vertical;

  padding: 8px 12px;

  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary);

  box-sizing: border-box;

  font-size: 18px;
}

.Quote:focus {
  border-width: 2px;
}

.BottomLine {
  margin-top: 8px;
}

.BottomLine > span {
  display: block;
  overflow: hidden;
}

.BottomLine svg {
  float: right;

  color: var(--color-primary);

  margin: 6px 16px;
}

.BottomLine svg:hover {
  transform: scale(1.1);
}

.Cited {
  width: 100%;

  padding: 8px 12px;

  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary);

  box-sizing: border-box;

  font-size: 18px;
}

.Cited:focus {
  border-width: 2px;
}
