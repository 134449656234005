.AppHeader {
  display: flex;

  height: 51px;

  padding: 0px 16px;

  color: var(--color-panel);
  background-color: var(--color-primary);
}

.Item {
  flex: 1;

  margin: auto 0px;
}

.Item:nth-child(1) {
  text-align: left;
}

.Item:nth-child(2) {
  text-align: center;
  font-weight: 500;
}

.Item:nth-child(3) {
  text-align: right;
}
