.Card {
  padding: 16px;
  margin-bottom: 2px;

  color: var(--color-text);
  background-color: var(--color-panel);
}

.Quote {
  font-size: 18px;
}

.CitedName {
  font-weight: 500;
  font-size: 14px;

  border-bottom-style: solid;
  border-width: 2px;
  border-color: #f7f7f7;

  margin-bottom: 8px;
  padding-bottom: 8px;

  text-align: right;
}

.Infobar {
  display: flex;

  margin-top: 16px;
}

.ButtonGroup {
  display: flex;

  flex: 1;

  color: var(--color-primary);
}

.Button,
.ButtonDark {
  margin-right: 16px;
}

.Button:first-of-type {
  margin-right: 32px;
}

.Button:active,
.ButtonDark:active {
  transform: scale(1.2);
}

.ButtonDark {
  color: var(--color-text50);
}

.LikeCount {
  margin: auto 0px;

  font-size: 14px;
  font-weight: 500;
}

.TimeDate {
  flex: 1;
  font-size: 12px;
  color: var(--color-text50);

  margin: auto 0px;
  text-align: right;
}
