* {
  margin: 0;
  padding: 0;

  --color-primary: #ec583c;

  --color-panel: #ffffff;
  --color-text: #000000;
  --color-text50: #7b7b7b;

  --color-background: #e4e4e4;
}

body,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.PageLogin_Background__2SM3- {
  position: fixed;

  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.PageLogin_Background__2SM3- > img {
  display: block;
  width: 100%;
  height: 100%;
}

.PageLogin_Body__1W04v {
  position: fixed;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background-color: rgba(236, 89, 60, 0.9);
}

.PageLogin_Sector__G5FVG {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;

  -webkit-flex: 1 1;

          flex: 1 1;

  padding: 16px;
}

.PageLogin_Logo__1J26X {
  color: var(--color-panel);

  width: 100%;
  text-align: center;
}

.PageLogin_Logo__1J26X > *:nth-child(1) {
  font-weight: 500;
  font-size: 40px;
}

.PageLogin_Logo__1J26X > *:nth-child(2) {
  font-size: 20px;
}

.PageLogin_ButtonSignIn__Toe7q {
  width: 100%;

  margin: 16px;
  padding: 16px;

  border-style: none;
  font-weight: 600;
  font-size: 18px;
  text-align: center;

  color: var(--color-primary);
  background-color: var(--color-panel);
}

.PageLogin_ButtonSignIn__Toe7q:active {
  color: var(--color-panel);
  background-color: var(--color-primary);
}

.AppHeader_AppHeader___41rk {
  display: -webkit-flex;
  display: flex;

  height: 51px;

  padding: 0px 16px;

  color: var(--color-panel);
  background-color: var(--color-primary);
}

.AppHeader_Item__9-9df {
  -webkit-flex: 1 1;
          flex: 1 1;

  margin: auto 0px;
}

.AppHeader_Item__9-9df:nth-child(1) {
  text-align: left;
}

.AppHeader_Item__9-9df:nth-child(2) {
  text-align: center;
  font-weight: 500;
}

.AppHeader_Item__9-9df:nth-child(3) {
  text-align: right;
}

.ContentBody_ContentBody__2sAZQ {
  position: fixed;
  top: 51px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  overflow-y: auto;

  background: var(--color-background);
}

.QuoteCard_Card__qa1dc {
  padding: 16px;
  margin-bottom: 2px;

  color: var(--color-text);
  background-color: var(--color-panel);
}

.QuoteCard_Quote__MgzVZ {
  font-size: 18px;
}

.QuoteCard_CitedName__3kt5e {
  font-weight: 500;
  font-size: 14px;

  border-bottom-style: solid;
  border-width: 2px;
  border-color: #f7f7f7;

  margin-bottom: 8px;
  padding-bottom: 8px;

  text-align: right;
}

.QuoteCard_Infobar__1wsiB {
  display: -webkit-flex;
  display: flex;

  margin-top: 16px;
}

.QuoteCard_ButtonGroup__2VLiK {
  display: -webkit-flex;
  display: flex;

  -webkit-flex: 1 1;

          flex: 1 1;

  color: var(--color-primary);
}

.QuoteCard_Button__hEuYz,
.QuoteCard_ButtonDark__2zP3k {
  margin-right: 16px;
}

.QuoteCard_Button__hEuYz:first-of-type {
  margin-right: 32px;
}

.QuoteCard_Button__hEuYz:active,
.QuoteCard_ButtonDark__2zP3k:active {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.QuoteCard_ButtonDark__2zP3k {
  color: var(--color-text50);
}

.QuoteCard_LikeCount__20oP5 {
  margin: auto 0px;

  font-size: 14px;
  font-weight: 500;
}

.QuoteCard_TimeDate__1dAVT {
  -webkit-flex: 1 1;
          flex: 1 1;
  font-size: 12px;
  color: var(--color-text50);

  margin: auto 0px;
  text-align: right;
}

.NextPageCard_Card__Evt9T {
  padding: 8px;
  margin-bottom: 2px;

  text-align: center;

  color: var(--color-primary);
  background-color: var(--color-panel);
}

.SortingCard_Card__1V8ix,
.SortingCard_CardExpanded__2k9L6 {
  position: relative;

  font-size: 14px;
}

.SortingCard_CardExpanded__2k9L6 > .SortingCard_Head__32fKZ {
  font-weight: 500;

  color: var(--color-text);
}

.SortingCard_Head__32fKZ {
  display: -webkit-flex;
  display: flex;
  height: 40px;
  padding: 0px 16px;

  color: var(--color-text50);
}

.SortingCard_CurrentSort__1wn6H {
  -webkit-flex: 1 1;
          flex: 1 1;

  margin: auto 0;
}

.SortingCard_ExpandButton__DH7VT {
  -webkit-flex: 1 1;
          flex: 1 1;

  text-align: right;
  margin: auto 0;
}

.SortingCard_Card__1V8ix .SortingCard_ExpandButton__DH7VT > * {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.SortingCard_CardExpanded__2k9L6 .SortingCard_ExpandButton__DH7VT > * {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.SortingCard_Body__3aknl {
  height: 0px;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.SortingCard_CardExpanded__2k9L6 .SortingCard_Body__3aknl {
  height: auto;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.SortingCard_SortItem__Cb4Vc {
  padding: 16px 16px;
  color: var(--color-text);
}

.SortingCard_SortItem__Cb4Vc:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.SortingCard_SortItemDivider__1cSWI {
  width: 100%;
  height: 1px;

  opacity: 0.3;
  background-color: var(--color-text50);
}

.PageNewQuote_AddQuoteSegmentCard__sk6bf {
  padding: 8px;
  margin-bottom: 2px;

  text-align: center;

  color: var(--color-primary);
  background-color: var(--color-panel);
}

.NewQuoteSegmentCard_Card__sTQ8C {
  padding: 16px;
  margin-bottom: 2px;

  color: var(--color-text);
  background-color: var(--color-panel);
}

.NewQuoteSegmentCard_Quote__3UgHn {
  width: 100%;
  min-width: 100%;
  max-width: 100%;

  min-height: 32px;
  max-height: 128px;

  resize: vertical;

  padding: 8px 12px;

  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary);

  box-sizing: border-box;

  font-size: 18px;
}

.NewQuoteSegmentCard_Quote__3UgHn:focus {
  border-width: 2px;
}

.NewQuoteSegmentCard_BottomLine__3cEgj {
  margin-top: 8px;
}

.NewQuoteSegmentCard_BottomLine__3cEgj > span {
  display: block;
  overflow: hidden;
}

.NewQuoteSegmentCard_BottomLine__3cEgj svg {
  float: right;

  color: var(--color-primary);

  margin: 6px 16px;
}

.NewQuoteSegmentCard_BottomLine__3cEgj svg:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.NewQuoteSegmentCard_Cited__1wiDf {
  width: 100%;

  padding: 8px 12px;

  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary);

  box-sizing: border-box;

  font-size: 18px;
}

.NewQuoteSegmentCard_Cited__1wiDf:focus {
  border-width: 2px;
}

.ProfileCard_Card__29qh2 {
  padding: 32px 0px;
  margin-bottom: 2px;

  text-align: center;

  background-color: var(--color-panel);
}

.ProfileCard_Picture__1uftS {
  width: 100px;
  height: 100px;

  border-radius: 50%;

  background-color: #000;
}

.ProfileCard_Name__3Y4Au {
  margin-top: 16px;

  font-size: 18px;
  color: var(--color-text);
}

.ProfileCard_Likes__2NMjr {
  margin-top: 16px;

  font-size: 18px;
  color: var(--color-text50);
}

.BestCard_Card__vjNXv {
  padding: 16px;
  margin-bottom: 2px;

  background-color: var(--color-panel);
}

.BestCard_Title__1yvFo {
  margin-bottom: 16px;

  text-align: center;
  font-size: 18px;
  color: var(--color-text);
}

.BestCard_NoData__WXgoO {
  font-size: 14px;
  color: var(--color-text50);
  text-align: center;
}

.BestCard_Item__2Jeap {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 8px;
}

.BestCard_ItemPlace__2Yohi {
  color: var(--color-text);
  font-size: 18px;
  width: 64px;
}

.BestCard_ItemName__3Yeba {
  margin: auto 0px;
  font-size: 14px;
  color: var(--color-text);
  -webkit-flex: 1 1;
          flex: 1 1;
}

.BestCard_ItemScore__13Tko {
  margin: auto 0px;
  text-align: right;
  color: var(--color-text50);
  font-size: 14px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

