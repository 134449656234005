.Card {
  padding: 16px;
  margin-bottom: 2px;

  background-color: var(--color-panel);
}

.Title {
  margin-bottom: 16px;

  text-align: center;
  font-size: 18px;
  color: var(--color-text);
}

.NoData {
  font-size: 14px;
  color: var(--color-text50);
  text-align: center;
}

.Item {
  display: flex;
  margin-bottom: 8px;
}

.ItemPlace {
  color: var(--color-text);
  font-size: 18px;
  width: 64px;
}

.ItemName {
  margin: auto 0px;
  font-size: 14px;
  color: var(--color-text);
  flex: 1;
}

.ItemScore {
  margin: auto 0px;
  text-align: right;
  color: var(--color-text50);
  font-size: 14px;
  flex: 1;
}
