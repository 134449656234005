.Card {
  padding: 32px 0px;
  margin-bottom: 2px;

  text-align: center;

  background-color: var(--color-panel);
}

.Picture {
  width: 100px;
  height: 100px;

  border-radius: 50%;

  background-color: #000;
}

.Name {
  margin-top: 16px;

  font-size: 18px;
  color: var(--color-text);
}

.Likes {
  margin-top: 16px;

  font-size: 18px;
  color: var(--color-text50);
}
