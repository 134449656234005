.Background {
  position: fixed;

  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.Background > img {
  display: block;
  width: 100%;
  height: 100%;
}

.Body {
  position: fixed;

  display: flex;
  flex-direction: column;

  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background-color: rgba(236, 89, 60, 0.9);
}

.Sector {
  display: flex;
  align-items: flex-end;

  flex: 1;

  padding: 16px;
}

.Logo {
  color: var(--color-panel);

  width: 100%;
  text-align: center;
}

.Logo > *:nth-child(1) {
  font-weight: 500;
  font-size: 40px;
}

.Logo > *:nth-child(2) {
  font-size: 20px;
}

.ButtonSignIn {
  width: 100%;

  margin: 16px;
  padding: 16px;

  border-style: none;
  font-weight: 600;
  font-size: 18px;
  text-align: center;

  color: var(--color-primary);
  background-color: var(--color-panel);
}

.ButtonSignIn:active {
  color: var(--color-panel);
  background-color: var(--color-primary);
}
