* {
  margin: 0;
  padding: 0;

  --color-primary: #ec583c;

  --color-panel: #ffffff;
  --color-text: #000000;
  --color-text50: #7b7b7b;

  --color-background: #e4e4e4;
}

body,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
